import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import App from './App.vue';
import router from './router';
import store from './store/index';
import axios from './service/axios';
import 'element-plus/dist/index.css';
import './assets/icons/iconfont.css'

const app = createApp(App);

app.config.globalProperties.axios = axios;

app.use(ElementPlus);
app.use(router);
app.use(store);

router.beforeEach(async (to, from, next) => {
  if (to.meta.isLogin) {
    console.log(store.state);
    store.commit('initUser');
    if (store.state.userInfo === null) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

// 挂载应用
app.mount('#app');
