import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    component: () => import('@/views/home/Home.vue'),
    meta: { isLogin: true },
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: () => import('@/views/home/Page.vue'),
      },
      {
        path: '/VideoDownload',
        name: 'VideoDownload',
        component: () => import('@/views/video/VideoDownload.vue'), // 视频下载
      },
      {
        path: '/VideoRemoveWatermarks',
        name: 'VideoRemoveWatermarks',
        component: () => import('@/views/video/VideoRemoveWatermarks.vue'), // 视频去除水印
      },
      {
        path: '/VideoBackgroundRemoving',
        name: 'VideoBackgroundRemoving',
        component: () => import('@/views/video/VideoBackgroundRemoving.vue'), // 视频背景去除
      },
      {
        path: '/VideoAddSubtitles',
        name: 'VideoAddSubtitles',
        component: () => import('@/views/video/VideoAddSubtitles.vue'), // 视频字幕添加
      },
      {
        path: '/VideoSubtitleTranslation',
        name: 'VideoSubtitleTranslation',
        component: () => import('@/views/video/VideoSubtitleTranslation.vue'), // 视频字幕翻译
      },
      {
        path: '/VideoSimultaneousTranslation',
        name: 'VideoSimultaneousTranslation',
        component: () => import('@/views/video/VideoSimultaneousTranslation.vue'), // 视频同声翻译
      },
      {
        path: '/VideoSingleFaceChange',
        name: 'VideoSingleFaceChange',
        component: () => import('@/views/video/VideoSingleFaceChange.vue'), // 视频单人换脸
      },
      {
        path: '/VideoMultipleFaceSwap',
        name: 'VideoMultipleFaceSwap',
        component: () => import('@/views/video/VideoMultipleFaceSwap.vue'), // 视频多人换脸
      },
      {
        path: '/VideoCopywritingGenerates',
        name: 'VideoCopywritingGenerates',
        component: () => import('@/views/video/VideoCopywritingGenerates.vue'), // 文案生成视频
      },
      {
        path: '/AudioExtraction',
        name: 'AudioExtraction',
        component: () => import('@/views/audio/AudioExtraction.vue'), // 音频提取
      },
      {
        path: '/AudioToText',
        name: 'AudioToText',
        component: () => import('@/views/audio/AudioToText.vue'), // 音频转文字
      },
      {
        path: '/TextToAudio',
        name: 'TextToAudio',
        component: () => import('@/views/audio/TextToAudio.vue'), // 文字转语音
      },
      {
        path: '/SoundCloning',
        name: 'SoundCloning',
        component: () => import('@/views/audio/SoundCloning.vue'), // 声音克隆
      },
      {
        path: '/ColoringTheImage',
        name: 'ColoringTheImage',
        component: () => import('@/views/photo/ColoringTheImage.vue'), // 图片上色
      },
      {
        path: '/ImageGeneration',
        name: 'ImageGeneration',
        component: () => import('@/views/photo/ImageGeneration.vue'), // 图片生成
      },
      {
        path: '/PicturesSpeak',
        name: 'PicturesSpeak',
        component: () => import('@/views/photo/PicturesSpeak.vue'), // 图片说话
      },
      {
        path: '/PictureSingleFaceChange',
        name: 'PictureSingleFaceChange',
        component: () => import('@/views/photo/PictureSingleFaceChange.vue'), // 图片单人换脸
      },
      {
        path: '/PicturesManyPeopleChangeFace',
        name: 'PicturesManyPeopleChangeFace',
        component: () => import('@/views/photo/PicturesManyPeopleChangeFace.vue'), // 图片多人换脸
      },
      {
        path: '/BatchImageFaceSwapping',
        name: 'BatchImageFaceSwapping',
        component: () => import('@/views/photo/BatchImageFaceSwapping.vue'), // 批量图片换脸
      },
      {
        path: '/DigitalHuman',
        name: 'DigitalHuman',
        component: () => import('@/views/other/DigitalHuman.vue'), // 数字人
      },
      {
        path: '/SubscriptionService',
        name: 'SubscriptionService',
        component: () => import('@/views/payments/SubscriptionService.vue'), // 订阅服务
      },
      {
        path: '/PersonalInformation',
        name: 'PersonalInformation',
        component: () => import('@/views/other/PersonalInformation.vue'), // 个人信息
      },
    ],
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('@/views/other/Message.vue'), // 登录页面
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'), // 登录页面
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
