import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
  },
  getters: {},
  mutations: {
    initUser(state) {
      state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    },
    setUser(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    removeUser(state) {
      state.userInfo = { account: "", password: "", token: "" };
    },
  },
  actions: {},
  modules: {},
});

export default store;
